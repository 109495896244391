import { Serializer } from '@shared/resources/serializer';
import { Partner } from './partner';

export class PartnerSerializer implements Serializer<Partner> {
  fromJson(json: any): Partner {
    return new Partner({
      id: json.partnerId,
      name: json.displayName,
    });
  }

  toJson(partner: Partner): any {
    return {
      name: partner.name
    };
  }
}
